.chat-container {

    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    color: black;
}

.chat-main-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.chat-wrapper {
    width: 100%;
    min-height: 50vh;
    max-height: 50vh;
}